import React, {useEffect, useState} from 'react';
import {RootState, useAppSelector} from 'module/Module';
import {SubmitHandler, useFieldArray, useForm} from 'react-hook-form';
import {editLecture, getEditData, uploadVideo} from 'api/lecture/management';
import {fileController} from 'api/common/file';
import {useNavigate} from 'react-router-dom';

import defaultThumbnails from 'assets/images/global/default_lecture_thumbnail.png';
import iconTooltip from 'assets/images/global/icon_tooltip.svg';
import {useParams} from "react-router";
import { Video } from 'utils/type';

interface Tags {
  name: string,
}

type FormData = {
  title: string,
  description: string,
  tags: Array<Tags>,
  tagCheck: string,
  video: Video,
  videoCheck: string,
  isPublic: string,
  mine: boolean,
  file: object,
};

type File = {
  id: number,
  name: string,
  url: string
}

const CreateForm = () => {
  const navigate = useNavigate();
  const isAdmin:boolean = useAppSelector((state: RootState) => state.authentication.user.role === 'ROLE_ADMIN');
  const courseId = Number(useParams().id);

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    formState: {isSubmitting, errors}
  } = useForm<FormData>({
    defaultValues: {
      video: {
        type: "",
        name: "",
        key: "",
        length: 0,
        outlink: false,
        thumbnail: {
          id: 0,
          name: '',
          url: defaultThumbnails
        },
      }
    },
  });

  const getData = () => {
    getEditData(courseId).then((res: any) => {

      if(!isAdmin && !res.data.mine){
        alert('접근 권한이 없습니다.')
        navigate(-1)
      }

      let newObj:Array<Tags> = [];
      res.data.courseTags.forEach((x:any, i:number) => {
        newObj.push({name: x.name});
      });
      if(res.data.courseTags.length < 5) {
        for(let i = 0; i< 5-res.data.courseTags.length; i++) {
          newObj.push({name: ""});
        }
      }
      tagReplace(newObj);
      validateTags();
      setValue(`title`, res.data.title);
      setValue('description', res.data.description);
      setValue('isPublic', String(res.data.public));
      setValue('video', res.data.videoDto);

      let newFileObj:Array<File> = [];
      res.data.courseFiles.forEach((x:any, i:number) => {
        newFileObj.push( {
          id: x.id,
          name: x.name,
          url: x.url,
        })
        setFiles(newFileObj)
      })
    })
  }

  const [btnClick, setBtnClick] = useState<boolean>(false);

  useEffect(() => {
    getData();

    if(errors.videoCheck){
      window.scrollTo(0, 0);
    }
  }, [btnClick]);

  const {fields: tagField, append: tagAppend, replace: tagReplace} = useFieldArray({
    control,
    name: "tags",
  });

  const validateTags = () => {
    let flag:boolean = false;
    watch().tags.forEach(tag => {
      if(tag.name !== '') flag = true;
    })

    if(flag) setValue('tagCheck', "Y");
    else setValue('tagCheck', "N");
    trigger('tagCheck');
  }

  const [clickCheck, setClickCheck] = useState<boolean> (false);

  const postLecture: SubmitHandler<FormData> = () => {
    let tagsArray:Array<string> = [];
    setClickCheck(true);
    if(clickCheck) {
      alert('처리 중입니다. 잠시만 기다려 주세요.');
    }
    watch().tags.forEach(tag => {
      tagsArray.push(tag.name);
    })
    let data = {
      title: watch().title,
      description: watch().description,
      isPublic: watch().isPublic,
      files: files,
      tags: tagsArray,
      deletedFiles: deleteFiles,
      updateThumbnailId: watch().video.thumbnail.id,
    }

    editLecture(courseId, data).then((res: any) => {
      alert('강의 수정이 완료되었습니다.');
      navigate('/lecture/management/list')
    }).catch((err: any) => {
      if (err.response.data.code === 'TOKEN_EXPIRED') {
        alert(err.response.data.message);
      } else {
        alert('오류가 발생하였습니다.');
      }
      setClickCheck(false);
    });
  };

  const thumbnailChange = (eventObject: any) => {
    var formData = new FormData();
    const thumbnail = eventObject.target.files[0];
    formData.append("file", thumbnail);
    var fileReader = new FileReader();
    fileReader.readAsDataURL(thumbnail);
    fileReader.onload = function(e:any) {
      setValue('video.thumbnail.url', e.target.result);
    };
    fileController(formData).then((res: any) => {
      setValue('video.thumbnail.id', res.data.id)
    });
  }

  // upload file
  const [files, setFiles] = useState<Array<File>>([]);
  const [addFiles, setAddFiles] = useState<Array<File>>([]);

  const fileUpload = (eventObject: any) => {
    var formData = new FormData();
    const file = eventObject.target.files[0];
    formData.append("file", file);

    fileController(formData).then((res: any) => {
      const newFile = {
        name: res.data.name,
        id: res.data.id,
        url: res.data.url
      }
      setFiles([newFile, ...files]);
    }).catch((err: any) => {
      console.log(err);
    })
  };

  const [deleteFiles, setDeleteFiles] = useState<Array<number>>([]);

  // 추가된 파일 삭제
  const deleteFile = (e: any, id:number) => {
    const name = e.target.getAttribute('name');
    setAddFiles(addFiles.filter(file => file.name !== name));
  };

  // 초기 파일 삭제
  const deleteInitialFile = (e: any, id:number) => {
    const name = e.target.getAttribute('name');
    setFiles(files.filter(file => file.name !== name));
    setDeleteFiles([...deleteFiles, id]);
  }

  const backEvent = () => {
    let check = watch().description === '' && watch().title === '';
    let tagCheck = true;
    watch().tags.some( (tag) => {
      if(tag.name !== '') tagCheck = false;
      return tag.name !== '';
    })
    if(check && tagCheck) {
      navigate('/lecture/management/list');
    } else {
      if(window.confirm('강의등록 페이지에서 나가시겠습니까?')) navigate('/lecture/management/list');
    }
  };

  return (
    <form id="lecture_edit_form" onSubmit={handleSubmit(postLecture)}>
      <section className="create-form-wrap">
        <div className="form-common input-wrap name-wrap">
          <label htmlFor="input_title"><span
            className="require">*</span> 제목</label>
          <input type="text" id="input_title" placeholder="제목을 40자 내로 입력해 주세요."
                 maxLength={80}
                 aria-invalid={errors.title ? "true" : "false"}
                 {...register("title", {
                    required: "제목을 입력해 주세요.",
                    minLength: {value: 2, message: "2자 이상 입력해 주세요."},
                    maxLength: {value: 80, message: "80자 이내로 입력해 주세요."},

                    onChange: e => {
                      let eng_check = /^[a-zA-z ]+$/;
                      if(eng_check.test(e.target.value)) {
                        if(e.target.value.length >= 80) {
                          e.target.value = e.target.value.substr(0, 80);
                        }
                      } else {
                        if(e.target.value.length >= 40) {
                          e.target.value = e.target.value.substr(0, 40);
                        }
                      }
                    }
                })}/>
          {Object.keys(errors).length > 0 &&
            <div className="error-wrap">
              {errors.title && <p className="error-message"
                                  role="alert">{errors.title.message}</p>}
            </div>
          }
        </div>
        <div className="form-common input-wrap lecture-information-wrap">
          <label htmlFor="input_information"><span
            className="require">*</span> 강의 소개</label>
          <textarea id="input_information"
                    aria-invalid={errors.description ? "true" : "false"}
                    placeholder="강의 소개 내용을 500자 내로 작성해 주세요."
                    maxLength={500}
                    {...register("description", {
                      required: "강의 소개 내용을 입력해 주세요.",
                      minLength: {value: 2, message: "2자 이상 입력해 주세요."},
                      maxLength: {value: 500, message: "500자 이내로 입력해 주세요."}
                    })}></textarea>
          {Object.keys(errors).length > 0 &&
            <div className="error-wrap">
              {errors.description && <p className="error-message"
                                        role="alert">{errors.description.message}</p>}
            </div>
          }
        </div>

        <section className="upload-wrap">
          <div className="box-wrap">
            <h2>영상</h2>
            <div className="upload-box">
              <div className="video-wrap">
                <h3><span className="require">*</span> 영상 업로드</h3>
                <div className="video-content">
                  <div className="radio-wrap">
                    <input type="radio"
                          id='upload_by_file'
                          checked={watch().video.type === 'VIMEO'}
                          value="file" readOnly disabled/>
                    <label htmlFor='upload_by_file' className='tooltip-label'>
                      <span>파일 선택</span>
                      <img src={iconTooltip} alt=""/>
                      <span className="tooltip-box">
                        <span className="tooltip-content">최대 권장 용량 : <b>1GB</b></span>
                        <span className="tooltip-content">최대 권장 시간 : <b>1시간</b></span>
                        <span className="tooltip-content">권장 화질 : <b>720P</b></span>
                      </span>
                    </label>
                    <div className="file-wrap">
                      <input type='file' id='video_upload' disabled/>
                      <label htmlFor='video_upload'>파일 선택</label>
                    </div>
                  </div>
                  {(watch().video.name !== '' && watch().video.name !== null) && (
                    <div className="selected-video-wrap">
                      <p className="video-name">{watch().video.name}</p>
                      <span className="upload-complete">업로드 완료</span>
                    </div>
                  )}
                </div>
                <div className="video-content">
                  <div className="radio-wrap">
                    <input type="radio" id='upload_by_url'
                          checked={watch().video.type === 'YOUTUBE'}
                          value="url" readOnly/>
                    <label htmlFor='upload_by_url'>URL 입력</label>
                    <div className="input-wrap">
                      <input type="text"
                            defaultValue={watch().video.type === 'YOUTUBE' ? watch().video.url : ''}
                            placeholder="URL 등록" disabled/>
                    </div>
                  </div>
                  {getValues('video.type') !== 'file' &&
                    <div className="checkbox-wrap">
                      <input type="checkbox"
                            checked={watch().video.outlink}
                            id='check_youtube' readOnly disabled/>
                      <label htmlFor='check_youtube'>유튜브에서 보기</label>
                    </div>
                  }
                </div>
              </div>
              <div className="dim"></div>
            </div>
            <div className="thumbnail-wrap">
              <h3>썸네일 등록<span>(20MB 이하)</span></h3>
              <div className="thumbnail-content">
                <img src={watch().video.thumbnail ? watch().video.thumbnail.url : defaultThumbnails} alt=""/>
                <div className="sub-button-wrap">
                  <input type="file"
                          id='video_thumbnail'
                          className="input-thumbnail"
                          onChange={(e) => thumbnailChange(e)}
                          accept=".jpg, .jpeg, .png"/>
                  <label htmlFor='video_thumbnail' className="btn-thumbnail">사진 {watch().video.thumbnail.url !== defaultThumbnails ? '변경' : '선택'}</label>
                  {watch().video.thumbnail.url !== defaultThumbnails &&
                    <button type="button"
                      className="btn-thumbnail-delete"
                      onClick={() => {
                        setValue(`video.thumbnail.url`, defaultThumbnails);
                      }}>삭제하기</button>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="form-common input-wrap tag-wrap">
          <label htmlFor="input_tag">
            <span className="require">*</span> 태그<br/>
            <span className='tip'>회원가입 시 등록한 태그가 기본으로 등록됩니다. 강의와 관련된 태그를 추가해주세요.</span>
          </label>
          <div className="input-multi-wrap">
            {tagField.map((tag, index) => (
              <input type="text" id="input_tag"
                     key={index}
                     placeholder="태그 입력"
                     {...register(`tags.${index}.name`, {
                       onChange: e => {
                         let eng_check = /^[a-zA-z ]+$/;
                         let kor_eng_check = /^^(?=.*[A-Za-z])(?=.*[ㄱ-ㅎ|가-힣])[ㄱ-ㅎ|가-힣|a-z|A-Z|]*/;
                         if(eng_check.test(e.target.value)) {
                           if(e.target.value.length >= 40) {
                             e.target.value = e.target.value.substr(0, 40);
                           }
                          } else if(kor_eng_check.test(e.target.value)) {
                            if(e.target.value.length >= 30) {
                              e.target.value = e.target.value.substr(0, 30);
                            }
                          } else {
                           if(e.target.value.length >= 20) {
                             e.target.value = e.target.value.substr(0, 20);
                           }
                         }
                         validateTags();
                       },
                     })}/>
            ))}
            <input type="hidden" {...register('tagCheck', {
              required: "태그를 입력해 주세요.",
              pattern: {value:/Y/, message:'태그를 입력해 주세요.'},
            })}/>
          </div>
          {Object.keys(errors).length > 0 &&
            <div className="error-wrap">
              {errors.tagCheck && <p className="error-message"
                                 role="alert">태그를 입력해 주세요.</p>}
            </div>
          }
          {tagField.length <= 9 &&
            <div className="sub-button-wrap">
              <button className="btn-add"
                      type="button"
                      onClick={() =>
                        tagAppend({
                          name: "",
                        })
                      }>추가
              </button>
            </div>
          }
        </div>
        <div className="form-common radio-container">
          <p className="label">공개상태</p>
          <div className="radio-wrap">
            <input type="radio" id="is_public" value="true"
                   defaultChecked={true} {...register("isPublic", {required: false})}/>
            <label htmlFor="is_public">공개</label>
          </div>
          <div className="radio-wrap">
            <input type="radio" id="is_privacy"
                   value="false" {...register("isPublic", {required: false})}/>
            <label htmlFor="is_privacy">비공개</label>
          </div>
        </div>
        <p className="tip">공개 : 강의가 모든 사용자에게 전체 공개됩니다.<br />비공개 : 강의를 전체 공개하지 않습니다. </p>

        <div className="form-common file-wrap">
          <div className="input-wrap">
            <p className="label">파일첨부</p>
            <input type="file" id="input_file" onChange={fileUpload} accept=".pdf, .jpg, .png, .zip"/>
            <label htmlFor="input_file">첨부하기</label>
          </div>
          <p className="tip">업로드 가능한 첨부파일 종류 : PDF, JPG, PNG, ZIP 파일<br/>용량 : 파일 1개 당, 20MB 이하</p>
          {files && (
            <ul className="file-list">
              {files.map((file: File) => (
                <li key={file.id}>
                  <p className="file-name">{file.name}</p>
                  <button type="button" className="btn-delete-file"
                          name={file.name} onClick={(e) => deleteInitialFile(e, file.id)}></button>
                </li>
              ))}
              {addFiles.map((file: File) => (
                <li key={file.id}>
                  <p className="file-name">{file.name}</p>
                  <button type="button" className="btn-delete-file"
                          name={file.name} onClick={(e) => deleteFile(e, file.id)}></button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>

      <section className="button-wrap">
        <button type="button" className="btn-cancel" onClick={backEvent}>뒤로가기</button>
        <button className="btn-submit" disabled={clickCheck} onClick={()=>setBtnClick(!btnClick)}>수정하기</button>
      </section>
    </form>
  )
}

export default CreateForm;
